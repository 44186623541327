/**
 * `router`
 *  Handle form methods e.g converting it to json
 *
 */

/**
 * Parses out the query string to an object
 *
 * @param {object} context the page js context object
 * @return {object} the parsed query params
 */
export const parseQueryParams = (context) => {
  const queryParams = {};
  const params = new URLSearchParams(context.querystring);
  Array.from(params.keys()).forEach((key) => {
    queryParams[key] = params.get(key);
  });
  return queryParams;
};

/**
 * Validates that the page thats trying to be routed to is valid otherwise error
 *
 * @param {string} page the name of the page
 * @return {string} validate page
 */
export const validatePage = (page) => {
  if (!page) {
    return 'stores';
  }
  if (['stores', 'store', 'app', 'error'].indexOf(page) !== -1) {
    return page;
  }
  return 'error';
};

/* eslint-disable no-unused-expressions */
/**
 * Dynamically import the page
 *
 * @param {string} page the current page
 */
export const importPage = (page) => {
  switch (page) {
    case 'stores':
      import('./pages/stores-page');
      break;
    case 'store':
      import('./pages/store-page');
      break;
    case 'app':
      import('./pages/app-page');
      break;
    case 'error':
      import('./pages/error-page');
      break;
    default:
      import('./pages/error-page');
  }
};
/* eslint-enable no-unused-expressions */
