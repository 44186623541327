import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from '../elements/statuses';
import '@material/mwc-list';
import '../elements/store-name';
/**
 * `store-page`
 *  Display the information about a specific store
 */
export class StorePage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      details {
        margin: 2em 0;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.state === status.Errored) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.state === status.Loading) {
      return html`<p class="loading">Loading store apps...</p>`;
    }

    const apps = this.apps.map((app) => html`
      <a class="app" href="/stores/${this.store.Code}/apps/${app.id}">
        <mwc-list-item>${app.name}</mwc-list-item>
      </a>`);

    return html`
    <h2 class="title"><store-name .store=${this.store}></store-name> Apps</h2>
      <p class="summary">There are ${this.apps.length} app(s) in this store.</p>
      <mwc-list class="apps">
        ${apps}
      </mwc-list>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The store info */
      store: {type: Object},
      /** The list of apps for this store */
      apps: {type: Array},
      /** What state we are in */
      state: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.store = {};
    this.apps = [];
    this.state = status.Idle;
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'store' &&
      this.service?.getStores) {
      const storeCode = this.routeData?.params?.storeCode;
      this.load(storeCode);
    }
  }

  /**
   * Call to load the store info
   *
   * @param {string} storeCode the storeCode
   */
  async load(storeCode) {
    this.state = status.Loading;
    try {
      const loadingStores = this.service.getStores();
      const loadingApps = this.service.getApps(storeCode);

      const [stores, apps] = await Promise.all([loadingStores, loadingApps]);
      const store = stores.find((store) => store.Code === storeCode);
      if (!store) {
        throw new Error(`no store found for ${storeCode}`);
      }

      this.store = store;
      this.apps = apps;
      this.state = status.Loaded;
    } catch (e) {
      this.state= status.Errored;
    }
  }
}
window.customElements.define('store-page', StorePage);
