import {LitElement, html} from 'lit';
/**
 * `store-name`
 *  Display the store code and domain
 */
export class StoreName extends LitElement {
  /**
     * Render template without shadow DOM.
     * @return {any} the render root
     */
  createRenderRoot() {
    return this;
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`${this.store.Code} - ${this.store.ShopifyDomain}`;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The store info */
      store: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.store = {};
  }
}
window.customElements.define('store-name', StoreName);
