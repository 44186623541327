/**
 * `service`
 *  Handle the sending of requests, done in this way so all requests
 *  can use the same method and updates only required in one place
 *
 */
export default class Service {
  /**
   * Constructs class
   *
   * @param {Auth} auth the auth service
   * @param {string} apiURI the root uri of api
   */
  constructor(auth, apiURI) {
    this.auth = auth;
    this.root = apiURI;
  }

  /**
   * Creates a headers object including the accepting only json responses
   * Adding any authorization header
   *
   * @return {object} Returns the body
   */
  async getHeaders() {
    const header = new Headers();
    header.set('accept', 'application/json');
    header.set('content-type', 'application/json');
    try {
      const token = await this.auth.getTokenSilently();
      header.set('Authorization', `Bearer ${token}`);
    } catch (e) {
      // squash login error as just don't add the header
    }
    return header;
  }

  /**
   * Used to send the requests off, if 401 unauthorised then redirect to
   * the login page
   *
   * @param {string} url The end point you wish to make the request to
   * @param {string} method The request method, defaults to get e.g. GET, POST
   * @param {object} body Any body you want to send off
   * @return {Promise<object>} Returns a promise based on the request
   */
  async send(url, method, body) {
    const headers = await this.getHeaders();
    const requestOptions = {
      url,
      method,
      mode: 'cors',
      cache: 'default',
      headers,
    };

    if (body) {
      const requestBody = JSON.stringify(body);
      requestOptions.body = requestBody;
    }

    const request = new Request(`${this.root}${requestOptions.url}`, requestOptions);
    try {
      const res = await fetch(request);
      if (res.ok && res.status === 204) {
        return;
      }
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        await this.auth.loginWithRedirect({
          redirect_uri: window.location.origin,
        });
        return;
      }
      return Promise.reject(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Get stores
   *
   * @return {Promise} the response
   */
  async getStores() {
    return this.send('/stores');
  }

  /**
   * Get apps for a specific store
   *
   * @param {string} storeCode the store code
   * @return {Promise} the response
   */
  async getApps(storeCode) {
    return this.send(`/stores/${storeCode}/apps`);
  }

  /**
   * Get webhooks for a specific store and app
   *
   * @param {string} storeCode the store code
   * @param {string} appID the store code
   * @return {Promise} the response
   */
  async getWebhooks(storeCode, appID) {
    return this.send(`/stores/${storeCode}/apps/${appID}/webhooks`);
  }

  /**
  * Create a webhook for a store
  *
  * @param {string} storeCode the store code
   * @param {string} appID the store code
  * @param {any} data hold the data for the break
  * @return {Promise} the response
  */
  async createWebhook(storeCode, appID, data) {
    return this.send(`/stores/${storeCode}/apps/${appID}/webhooks`, 'POST', data);
  }

  /**
  * Delete a webhook for a store
  *
  * @param {string} storeCode the store code
   * @param {string} appID the store code
  * @param {string} id the shopify id of the webhook
  * @return {Promise} the response
  */
  async deleteWebhook(storeCode, appID, id) {
    return this.send(`/stores/${storeCode}/apps/${appID}/webhooks`, 'DELETE', {id});
  }
}

