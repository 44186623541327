import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from '../elements/statuses';
import '@material/mwc-list';
import '../elements/store-name';
/**
 * `stores-page`
 *  Display the list of stores for a user
 */
export class StoresPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      div.stores {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      div.stores > a.store {
        padding: 10px 5px;
      }
    `;

    return [sharedStyles, style];
  }
  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.state === status.Errored) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.state === status.Loading) {
      return html`<p class="loading">Loading the stores...</p>`;
    }
    if (!this.stores.length) {
      const empty = html`<p class="empty">You do not have any stores you can manage webhooks in</p>`;
      return empty;
    }

    const stores = this.stores.map((store) => html`
      <a class="store" href="/stores/${store.Code}">
        <mwc-list-item><store-name .store=${store}></store-name></mwc-list-item>
      </a>`);
    const content = html`
      <h2>Stores</h2>
      <p class="summary">Please find the stores you can manage webhooks in, listed below:</p>
      <mwc-list class="stores">
        ${stores}
      </mwc-list>
    `;
    return content;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The list of stores */
      stores: {type: Array},
      /** What state we are in */
      state: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.stores = [];
    this.state = status.Idle;
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('service') || changedProperties.has('routeData')) &&
      this.service?.getStores && this.routeData?.params?.page === 'stores') {
      this.loadStores();
    }
  }

  /**
   * Call to load the list of stores
   */
  async loadStores() {
    this.state = status.Loading;
    try {
      const response = await this.service.getStores();
      if (response) {
        this.stores = response;
      }
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
    }
  }
}
window.customElements.define('stores-page', StoresPage);
