import {LitElement, html, css} from 'lit';
/**
 * `webhook-group`
 *  Display a group of webhooks
 */
export class WebhookGroup extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
        :host {
            display: block;
            color: var(--white);
            margin: 20px 0;
        }

        .topic {
          margin: 20px 0 0 0;
        }

        div.webhooks {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
        }
      `;

    return [style];
  }
  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    const webhooks = this.group.sort().map((hookData) => html`
    <webhook-item .webhook=${hookData} .service=${this.service} storeCode=${this.storeCode} appID=${this.appID}>
    </webhook-item>
    `);

    return html`
        <h3 class="topic">${this.topic}</h3>
        <div class="webhooks">
          ${webhooks}
        </div>
    `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The webhook info */
      group: {type: Object},
      /** The webhook topic */
      topic: {type: String},
      /** The service for getting data */
      service: {type: Object},
      /** The storeCode of the webhook */
      storeCode: {type: String},
      /** The appID of the webhook */
      appID: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.group = {};
    this.topic = '';
    this.service = {};
    this.storeCode = '';
    this.appID = '';
  }
}
window.customElements.define('webhook-group', WebhookGroup);
