import {css} from 'lit';

/* shared styles for all views */
export const sharedStyles = css`
a, mwc-list-item {
  color: var(--black);
  font-weight: 500;
}
a:visited {
  color: var(--black);
}

@media (prefers-color-scheme: dark) {
  a, mwc-list-item {
    color: var(--white);
    font-weight: 500;
  }
  a:visited {
    color: var(--white);
  }
}
`;
