import {LitElement, html, css} from 'lit';
import {status} from './statuses';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-dialog';
/**
 * `webhook-item`
 *  Display the webhook info
 */
export class WebhookItem extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
        :host {
            display: block;
            border-radius: 5px;
            margin: 10px 10px;
            padding: 20px;
            background: whitesmoke;
            color: var(--black);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        mwc-textfield {
            display: block;
        }
        
        mwc-button[raised] {
          --mdc-theme-primary: #d8252c;
          --mdc-theme-on-primary: #fff;
        }
      `;

    return [style];
  }
  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
        <small class="id">ID: ${this.webhook.id}</small>
        <p class="endpoint">${this.webhook.endpoint}</p>
        <p class="fields" ?hidden=${this.webhook?.fields?.length === 0}>fields: ${this.webhook?.fields?.sort().join(', ')}</p>
        <mwc-button class="delete" raised icon="delete" @click=${this.toggleDialog}>Delete webhook</mwc-button>
        <mwc-dialog heading="Delete Webhook">
            <p>Please confirm deleting a webhook by entering <b>${this.webhook.id}</b>.</p>
            <mwc-textfield class="id" label="Webhook ID" placeholder="gid://" required pattern="${this.webhook.id}">
            </mwc-textfield>
            <p class="error" ?hidden=${this.state !== status.Errored}>Oops there has been an error.</p>
            <p class="saving" ?hidden=${this.state !== status.Saving}>Deleting webhook.</p>
            <mwc-button class="submit" raised slot="primaryAction" @click=${this.deleteWebhook}>
                Confirm
            </mwc-button>
            <mwc-button class="cancel" slot="secondaryAction" dialogAction="close">
                Cancel
            </mwc-button>
        </mwc-dialog>
    `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The webhook info */
      webhook: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The storeCode of the webhook */
      storeCode: {type: String},
      /** The appID of the webhook */
      appID: {type: String},
      /** What state we are in */
      state: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.webhook = {};
    this.service = {};
    this.storeCode = '';
    this.appID = '';
    this.state = status.Idle;
  }

  /**
     * Helper to get the dialog box
     * @return {HTMLElement} the dialog
     */
  get dialog() {
    return this.shadowRoot.querySelector('mwc-dialog');
  }

  /**
     * Shows the delete dialog
     */
  toggleDialog() {
    this.dialog.show();
  }

  /**
     * Deletes the webhook
     * Shows or hides the messages depending on state
     */
  async deleteWebhook() {
    const input = this.shadowRoot.querySelector('mwc-textfield');
    const isValid = input.reportValidity();
    if (!isValid) {
      return;
    }

    try {
      this.state = status.Saving;
      await this.service.deleteWebhook(this.storeCode, this.appID, this.webhook.id);
      this.dialog.close();
      this.state = status.Saved;
      this.dispatchEvent(new CustomEvent('webhook-deleted', {
        bubbles: true,
        composed: true,
      }));
    } catch (e) {
      this.state = status.Errored;
    }
  }
}
window.customElements.define('webhook-item', WebhookItem);
