import {
  LitElement, html, css,
} from 'lit';
import router from 'page/page.mjs';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import {parseQueryParams, validatePage, importPage} from './router';
import {sharedStyles} from './shared-styles';
import {status} from './elements/statuses';
import Service from './elements/service';
import 'wc-spinners/dist/hollow-dots-spinner';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-button';

/**
 * `app-shell`
 *  Shell to run the whole app
 */
export class AppShell extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      :host {
        display: block;
      }

      main {
        padding: 25px;
      }

      main.loading,
      main.error,
      main.unauthenticated {
        display: flex;
        height: 25vh;
      }

      main.loading>hollow-dots-spinner {
        margin: auto;
        --hollow-dots-spinner__color: #6200ee;
      }

      main.error>p.error {
        margin: auto;
      }

      main.unauthenticated {
        flex-direction: column;
      }

      main.unauthenticated>h3 {
        margin: auto auto 0 auto;
      }

      main.unauthenticated>mwc-button {
        width: 50vw;
        margin: auto;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the navbar content
   *
   * @return {TemplateResult} the resulting html template
   */
  get nav() {
    if (!this.isAuthenticated) {
      return html`<mwc-button class="login" raised @click=${this.login}>Login</mwc-button>`;
    }

    return html`<mwc-button class="logout" raised @click=${this.logout}>Log out</mwc-button>`;
  }

  /**
   * Defined the header content
   *
   * @return {TemplateResult} the resulting html template
   */
  get header() {
    return html`
    <mwc-top-app-bar-fixed>
      <a slot="title" href="/">
        <h1>Webhooks</h1>
      </a>
      <nav slot="actionItems">
        ${this.nav}
      </nav>
  </mwc-top-app-bar-fixed>
    `;
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.state === status.Errored) {
      const message = html`
      <main class="error">
        <p class="error">Oops there has been an error. <a href="/">Go home</a></p>
      </main>`;
      return [this.header, message];
    }

    if (this.state === status.Loading) {
      const message = html`
      <main class="loading">
        <hollow-dots-spinner></hollow-dots-spinner>
      </main>`;
      return [this.header, message];
    }

    if (!this.isAuthenticated) {
      const content = html`
        <main class="unauthenticated">
          <h3>You are required to login to access webhooks.</h3>
          <mwc-button class="login" raised @click=${this.login}>Login</mwc-button>
        </main>
      `;
      return [this.header, content];
    }

    const content = html`
      <main class="authenticated">
        <stores-page
          ?hidden=${this.page !== 'stores'}
          .routeData=${this.routeData}
          .service=${this.service}
        ></stores-page>
        <store-page
          ?hidden=${this.page !== 'store'}
          .routeData=${this.routeData}
          .service=${this.service}
        ></store-page>
        <app-page
          ?hidden=${this.page !== 'app'}
          .routeData=${this.routeData}
          .service=${this.service}
        ></app-page>
        <error-page
          ?hidden=${this.page !== 'error'}>
        </error-page>
      </main>`;
    return [this.header, content];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The page to display */
      page: {type: String, reflect: true},
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The query params from the route url */
      queryParams: {type: Object},
      /** The auth client */
      auth: {type: Object},
      /** The http client */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** If the user is authenticated */
      isAuthenticated: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {
      params: {},
    };
    this.queryParams = {};
    this.state = status.Loading;
    this.isAuthenticated = false;
    this.routing();
  }

  /** Add any event listeners */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.auth = await createAuth0Client({
      domain: AUTH_DOMAIN,
      clientId: AUTH_CLIENT_ID,
      authorizationParams: {
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      },
    });
    this.service = new Service(this.auth, API_URL);

    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await this.auth.handleRedirectCallback();
      window.history.replaceState({}, document.title, '/');
      this.isAuthenticated = await this.checkAuthenticatedStatus();
    }
  }

  /** Client side routing */
  routing() {
    // Parses off any query strings from the url and sets a query string object
    // url ?hi=everyone
    // queryParams {hi: 'everyone'}
    router('*', (context, next) => {
      this.queryParams = parseQueryParams(context);
      next();
    });
    // Browsing to / takes you to channels
    router('/', (context) => {
      const {...routeData} = context;
      routeData.params.page = 'stores';
      this.routeData = routeData;
    });
    // Browsing to /home tries to take you to that page
    router('/:page', (context) => {
      this.routeData = context;
    });
    router('/stores/:storeCode', (context) => {
      const {...routeData} = context;
      routeData.params.page = 'store';
      this.routeData = routeData;
    });
    router('/stores/:storeCode/apps/:appID', (context) => {
      const {...routeData} = context;
      routeData.params.page = 'app';
      this.routeData = routeData;
    });
    router();
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  async updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }
    if ((changedProperties.has('routeData') || changedProperties.has('auth')) && this.routeData?.params?.page && this.auth?.isAuthenticated) {
      this.isAuthenticated = await this.checkAuthenticatedStatus();
      this.page = validatePage(this.routeData.params.page);
    }
    if (changedProperties.has('page')) {
      importPage(this.page);
    }
  }

  /**
   * Call to check if the user is authenticated
   */
  async checkAuthenticatedStatus() {
    this.state = status.Loading;
    try {
      const isAuthenticated = await this.auth.isAuthenticated();
      this.state = status.Loaded;
      return isAuthenticated;
    } catch (e) {
      this.state = status.Errored;
    }
  }

  /**
   * Login the user
   */
  async login() {
    this.auth.loginWithRedirect();
  }

  /**
   * Log out the user
   */
  async logout() {
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    router.show('/');
  }
}

window.customElements.define('app-shell', AppShell);
