import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from '../elements/statuses';
import '@material/mwc-textfield';
import '../elements/store-name';
import '../elements/webhook-item';
import '../elements/webhook-group';
import '../elements/add-webhook';
/**
 * `app-page`
 *  Display the information about a specific app
 */
export class AppPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      mwc-textfield {
        margin-top: 20px;
        display: block;
      }

      div.webhook-groups {
        margin: 30px 0;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.state === status.Errored) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.state === status.Loading) {
      return html`<p class="loading">Loading app and webhooks...</p>`;
    }

    const groupedHooks = {};
    for (const hook of this.webhooks) {
      if (!groupedHooks[hook.topic]) {
        groupedHooks[hook.topic] = [];
      }
      groupedHooks[hook.topic].push(hook);
    }

    const webhookGroups = Object.entries(groupedHooks).map(([topic, group]) => html`
    <webhook-group .topic=${topic} .group=${group} .service=${this.service} storeCode=${this.store.Code} appID=${this.app.id}>
    </webhook-group>
    `);

    return html`
    <h2 class="title">
      <store-name .store=${this.store}></store-name>
    </h2>
    <h3>${this.app.name} Webhooks</h3>
    <p class="summary">There are ${this.webhooks.length} webhook(s) for this app in this store.</p>
    <add-webhook .service=${this.service} storeCode=${this.store.Code} appID=${this.app.id}></add-webhook>
    <div class="webhook-groups">
      ${webhookGroups}
    </div>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The store info */
      store: {type: Object},
      /** The app info */
      app: {type: Object},
      /** The list of webhooks for this store */
      webhooks: {type: Array},
      /** What state we are in */
      state: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.store = {};
    this.app = {};
    this.webhooks = [];
    this.state = status.Idle;
  }

  /** Add any event listeners */
  connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.addEventListener('webhook-added', this.reload);
    this.addEventListener('webhook-deleted', this.reload);
  }

  /** Remove any event listeners */
  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
    this.removeEventListener('webhook-added', this.reload);
    this.removeEventListener('webhook-deleted', this.reload);
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'app' &&
      this.service?.getStores) {
      const storeCode = this.routeData?.params?.storeCode;
      const appID = this.routeData?.params?.appID;
      this.load(storeCode, appID);
    }
  }

  /**
   * Call to load the store info
   *
   * @param {string} storeCode the storeCode
   * @param {string} appID the appID
   */
  async load(storeCode, appID) {
    this.state = status.Loading;
    try {
      const loadingStores = this.service.getStores();
      const loadingApps = this.service.getApps(storeCode);
      const loadingWebhooks = this.service.getWebhooks(storeCode, appID);

      const [stores, apps, webhooks] = await Promise.all([loadingStores, loadingApps, loadingWebhooks]);
      const store = stores.find((store) => store.Code === storeCode);
      if (!store) {
        throw new Error(`no store found for ${storeCode}`);
      }
      const app = apps.find((app) => app.id === appID);
      if (!store) {
        throw new Error(`no store found for ${storeCode}`);
      }

      this.store = store;
      this.app = app;
      this.webhooks = AppPage.sortWebhooksByTopic(webhooks);
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
    }
  }

  /**
   * Sort the list of webhooks by topic alphabetically
   * @param {Array} webhooks the list of webhooks from the API
   * @return {Array} the sorted list
   */
  static sortWebhooksByTopic(webhooks) {
    return webhooks.sort((a, b) => {
      if (a.topic < b.topic) {
        return -1;
      }
      if (a.topic > b.topic) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Reload the data
   */
  async reload() {
    return this.load(this.store.Code, this.app.id);
  }
}
window.customElements.define('app-page', AppPage);
