import {LitElement, html, css} from 'lit';
import {status} from './statuses';
import '@material/mwc-textfield';
import '@material/mwc-dialog';
/**
 * `add-webhook`
 *  Handles adding a webhook
 */
export class AddWebhook extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      mwc-dialog {
        --mdc-dialog-max-width: 1000px;
      }

      mwc-textfield {
          display: block;
          margin: 15px;
          min-width: 750px;
      }

      small, p {
        margin-left: 24px;
      }

      @media (max-width: 769px) {
        mwc-textfield {
            min-width: 500px;
        }
      }
    `;

    return [style];
  }
  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
      <mwc-button class="add" raised icon="add" @click=${this.toggleDialog}>Add a webhook</mwc-button>
      <mwc-dialog heading="Add a Webhook">
        <small>Must be one of
          <a href="https://shopify.dev/api/admin-graphql/2023-01/enums/WebhookSubscriptionTopic" target="_blank">supported
            graphql types</a></small>
        <mwc-textfield class="topic" label="Topic" required></mwc-textfield>
        <mwc-textfield
          class="url"
          label="URL (Optional)"
          type="url"
          helper="This is the endpoint you want the webhooks to be sent to"
          helperPersistent></mwc-textfield>
        <mwc-textfield
          class="arn"
          label="ARN (Optional)"
          helper="If the app is a Shopify custom app, you can use the ARN of the partner event bus here"
          helperPersistent></mwc-textfield>
        <mwc-textfield
          class="fields"
          label="Fields (Optional)"
          helper="Any fields you want to include on the webhook, these must be supported by Shopify. They should be comma seperated"
          helperPersistent></mwc-textfield>
        <p class="error" ?hidden=${this.state !== status.Errored}>Oops there has been an error.</p>
        <p class="saving" ?hidden=${this.state !== status.Saving}>Saving webhook.</p>
        <mwc-button class="submit" raised slot="primaryAction" @click=${this.createWebhook}>
          Confirm
        </mwc-button>
        <mwc-button class="cancel" slot="secondaryAction" dialogAction="close">
          Cancel
        </mwc-button>
      </mwc-dialog>`;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The service for getting data */
      service: {type: Object},
      /** The storeCode of the webhook */
      storeCode: {type: String},
      /** The appID of the webhook */
      appID: {type: String},
      /** If we're saving the data */
      isSaving: {type: Boolean},
      /** What state we are in */
      state: {type: String},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.storeCode = '';
    this.appID = '';
    this.state = status.Idle;
  }

  /**
   * Helper to get the dialog box
   * @return {HTMLElement} the dialog
   */
  get dialog() {
    return this.shadowRoot.querySelector('mwc-dialog');
  }

  /**
   * Shows the delete dialog
   */
  toggleDialog() {
    this.dialog.show();
  }

  /**
     * Creates the webhook
     * Shows or hides the messages depending on state
     */
  async createWebhook() {
    const topic = this.shadowRoot.querySelector('mwc-textfield.topic');
    const url = this.shadowRoot.querySelector('mwc-textfield.url');
    const arn = this.shadowRoot.querySelector('mwc-textfield.arn');
    const fields = this.shadowRoot.querySelector('mwc-textfield.fields');

    const isValid = topic.reportValidity() && url.reportValidity() && arn.reportValidity() && fields.reportValidity();
    if (!isValid) {
      return;
    }

    try {
      this.state = status.Saving;
      const fieldsValue = fields.value.split(',').map((item) => item.trim()).filter((item) => item);
      const data = {
        topic: topic.value,
        url: url.value,
        arn: arn.value,
        fields: fieldsValue,
      };
      await this.service.createWebhook(this.storeCode, this.appID, data);
      this.dialog.close();
      this.state = status.Saved;
      this.dispatchEvent(new CustomEvent('webhook-added', {
        bubbles: true,
        composed: true,
      }));
    } catch (e) {
      this.state = status.Errored;
    }
  }
}
window.customElements.define('add-webhook', AddWebhook);
